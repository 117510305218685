@use './breakpoints' as *;

.header {
  padding: 20px 0px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  @include respond-to('small') {
    flex-direction: row;
    padding: 39px 30px;
  }
  @include respond-to('large') {
    flex-direction: row;
    padding: 39px 0px;
  }

  .nav-logo {
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.444444px;

    @include respond-to('small') {
      font-size: 32px;
    }
  }

  .nav-links {
    display: flex;
    gap: 32px;
  }

  .nav-link {
    height: 24px;
    width: 24px;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);

      filter: brightness(0) saturate(100%) invert(100%) sepia(26%)
        saturate(4586%) hue-rotate(70deg) brightness(94%) contrast(89%);
    }
  }
}

.hero {
  margin-top: -10px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  // flex-direction: column;

  @include respond-to('small') {
    display: block;
    margin-top: 36px;
    position: relative;
  }

  &:before {
    content: url('./img/svg/3dRing.svg');
    // overflow: visible;
    position: absolute;
    top: -2%;
    left: -390px;

    @include respond-to('medium') {
      top: 2%;
      left: -274px;
    }
  }

  &:after {
    content: url('./img/svg/half-oval.svg');
    // overflow: visible;
    position: absolute;
    top: 34%;
    right: -0px;
    // display: none;

    @include respond-to('small') {
      content: url('./img/svg/Oval.svg');

      top: 65%;
      // display: block;
      right: 152px;
      opacity: 1;
    }

    @include respond-to('medium') {
      top: 70%;
      display: block;
      right: 381px;
      opacity: 1;
    }
  }

  .hero-img-container {
    display: flex;
    justify-content: end;

    @include respond-to('small') {
      padding-bottom: 80px;
    }
    @include respond-to('medium') {
      padding-bottom: 0;
    }
  }
  .hero-img {
    filter: blur(5px) grayscale(100%);
    height: 383px;
    width: 210px;
    object-fit: cover;
    object-position: top;
    mask-image: linear-gradient(to top, black 80%, rgba(0, 0, 0, 0) 100%);

    @include respond-to('medium') {
      height: 520px;
      width: 445px;
    }
  }

  .hero-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 46px;

    @include respond-to('small') {
      margin-top: 0px;

      display: block;
      position: absolute;
      top: 10%;
    }
  }
  .name-span {
    border-bottom: 6px solid var(--c-green);
  }

  .hero-header {
    text-align: center;
    @include respond-to('small') {
      text-align: left;
    }
  }

  .hero-p {
    margin: 43px 0 66px 0;
    padding: 0 20px;
    max-width: 445px;
    text-align: center;

    @include respond-to('small') {
      text-align: left;
      padding: 0;
    }
  }
}

.skills {
  margin-top: 104px;
  margin-bottom: 140px;
  padding-top: 72px;
  padding: 72px 24px 0px 24px;
  border-top: 1px solid var(--c-white);
  position: relative;
  text-align: center;

  @include respond-to('small') {
    text-align: start;
  }

  &:after {
    content: url('./img/svg/half-circle.svg');
    position: absolute;
    bottom: -9%;
    right: -2px;
    // right: -255px;
    width: 300px;

    @include respond-to('small') {
      bottom: 12%;
    }
    @include respond-to('medium') {
      bottom: -18%;
      // right: 0;
    }
    @include respond-to('large') {
      content: url('./img/svg/3dRing.svg');
      // content: url('./img/svg/half-circle.svg');

      bottom: 33%;
      left: 64%;
    }
  }

  .tech-skills-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // gap: 34px;
    // flex-wrap: wrap;
    margin-top: 24px;
    margin-bottom: 48px;
    align-items: center;

    @include respond-to('small') {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: start;
    }
  }
  .soft-skills-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // flex-wrap: wrap;
    margin-top: 24px;
    margin-bottom: 48px;
    align-items: center;

    @include respond-to('small') {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: start;
    }
  }
  .skills-title {
    color: var(--c-gray-light);
    border-bottom: 1px solid var(--c-gray-light);
    display: inline-block;
  }
  .tech-skill-item {
    // width: 100%;
    margin-bottom: 24px;

    @include respond-to('small') {
      width: 50%;
    }
    @include respond-to('medium') {
      width: 30%;
    }
  }
  .soft-skill-item {
    // width: 50%;
    margin-bottom: 24px;

    @include respond-to('small') {
      width: 100%;
    }
    @include respond-to('medium') {
      width: 50%;
    }
  }
}

.projects {
  margin-bottom: 139px;
  padding: 0 24px;

  .projects-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
  }

  .projects-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @include respond-to('small') {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 24px;
    }
  }

  .project-clump {
    width: 100%;
    margin-bottom: 69px;
    position: relative;
    // z-index: 1;

    @include respond-to('small') {
      width: 47%;
    }
  }

  .img-div {
    aspect-ratio: 4/3;
    // max-height: 400px;
    max-width: 540px;
    position: absolute;
    // left: 2%;
    z-index: -1;
  }
  .project-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .project-hover-area {
    aspect-ratio: 4/3;
    // height: 400px;
    // max-height: 400px;
    // width: 100%;
    max-width: 541px;
    // margin: 0 auto;
    margin-bottom: 20px;
    // cursor: pointer;
    transition: all 0.2s;
    // background-color: white;

    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
      opacity: 1;
    }
  }

  .tech-container {
    display: flex;
    column-gap: 8px;
    flex-wrap: wrap;

    @include respond-to('small') {
      column-gap: 24px;
    }
  }
  .tech-item {
    // display: inline-block;
    text-transform: uppercase;
    margin-top: 7px;
  }
}

.contact {
  background-color: var(--c-gray);
  padding: 84px 0px 92px 0px;
  // z-index: 1;

  .contact-text {
    text-align: center;

    @include respond-to('medium') {
      text-align: left;
    }
  }
  .contact-p {
    max-width: 445px;
    margin-top: 36px;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100%;
    max-width: 445px;
  }

  .contact-container {
    max-width: 1110px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    align-items: center;
    gap: 48px;

    @include respond-to('medium') {
      flex-direction: row;
      align-items: start;
      gap: 24px;
    }
    @include respond-to('large') {
      flex-direction: row;
      align-items: start;
      gap: 0;
    }

    &::before {
      content: url('./img/svg/3dRing.svg');
      position: absolute;
      bottom: 2px;
      left: -400px;

      @include respond-to('small') {
        bottom: -78px;
        left: -300px;
      }
      @include respond-to('medium') {
        top: 255px;
        left: -257px;
      }
    }
  }
}

.footer {
  padding-bottom: 92px;
  background-color: var(--c-gray);

  .footer-container {
    padding: 47px 20px 0px 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-top: 1px solid var(--c-white);
    max-width: 1120px;

    @include respond-to('small') {
      flex-direction: row;
      justify-content: space-between;
      gap: 0;
    }
  }
  .footer-links {
    display: flex;
    justify-content: space-between;
    width: 192px;
  }
  .footer-link {
    height: 24px;
    width: 24px;

    transition: transform 0.1s;

    &:hover {
      transform: scale(1.1);
      filter: brightness(0) saturate(100%) invert(100%) sepia(26%)
        saturate(4586%) hue-rotate(70deg) brightness(94%) contrast(89%);
    }
  }
}
