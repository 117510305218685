.header {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
  display: flex;
}

@media (min-width: 40rem) {
  .header {
    flex-direction: row;
    padding: 39px 30px;
  }
}

@media (min-width: 85rem) {
  .header {
    flex-direction: row;
    padding: 39px 0;
  }
}

.header .nav-logo {
  letter-spacing: -.444444px;
  font-family: Space Grotesk, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 40rem) {
  .header .nav-logo {
    font-size: 32px;
  }
}

.header .nav-links {
  gap: 32px;
  display: flex;
}

.header .nav-link {
  height: 24px;
  width: 24px;
  transition: transform .2s;
}

.header .nav-link:hover {
  filter: brightness(0) saturate() invert() sepia(26%) saturate(4586%) hue-rotate(70deg) brightness(94%) contrast(89%);
  transform: scale(1.1);
}

.hero {
  flex-direction: column;
  align-items: center;
  margin-top: -10px;
  display: flex;
  position: relative;
}

@media (min-width: 40rem) {
  .hero {
    margin-top: 36px;
    display: block;
    position: relative;
  }
}

.hero:before {
  content: url("3dRing.35c3a2b9.svg");
  position: absolute;
  top: -2%;
  left: -390px;
}

@media (min-width: 55rem) {
  .hero:before {
    top: 2%;
    left: -274px;
  }
}

.hero:after {
  content: url("half-oval.2402cfbd.svg");
  position: absolute;
  top: 34%;
  right: 0;
}

@media (min-width: 40rem) {
  .hero:after {
    content: url("Oval.e6ebdd21.svg");
    opacity: 1;
    top: 65%;
    right: 152px;
  }
}

@media (min-width: 55rem) {
  .hero:after {
    opacity: 1;
    display: block;
    top: 70%;
    right: 381px;
  }
}

.hero .hero-img-container {
  justify-content: end;
  display: flex;
}

@media (min-width: 40rem) {
  .hero .hero-img-container {
    padding-bottom: 80px;
  }
}

@media (min-width: 55rem) {
  .hero .hero-img-container {
    padding-bottom: 0;
  }
}

.hero .hero-img {
  filter: blur(5px) grayscale();
  height: 383px;
  width: 210px;
  object-fit: cover;
  object-position: top;
  -webkit-mask-image: linear-gradient(to top, #000 80%, #0000 100%);
  mask-image: linear-gradient(to top, #000 80%, #0000 100%);
}

@media (min-width: 55rem) {
  .hero .hero-img {
    height: 520px;
    width: 445px;
  }
}

.hero .hero-text-container {
  flex-direction: column;
  align-items: center;
  margin-top: 46px;
  display: flex;
}

@media (min-width: 40rem) {
  .hero .hero-text-container {
    margin-top: 0;
    display: block;
    position: absolute;
    top: 10%;
  }
}

.hero .name-span {
  border-bottom: 6px solid var(--c-green);
}

.hero .hero-header {
  text-align: center;
}

@media (min-width: 40rem) {
  .hero .hero-header {
    text-align: left;
  }
}

.hero .hero-p {
  max-width: 445px;
  text-align: center;
  margin: 43px 0 66px;
  padding: 0 20px;
}

@media (min-width: 40rem) {
  .hero .hero-p {
    text-align: left;
    padding: 0;
  }
}

.skills {
  border-top: 1px solid var(--c-white);
  text-align: center;
  margin-top: 104px;
  margin-bottom: 140px;
  padding: 72px 24px 0;
  position: relative;
}

@media (min-width: 40rem) {
  .skills {
    text-align: start;
  }
}

.skills:after {
  content: url("half-circle.9184f2d0.svg");
  width: 300px;
  position: absolute;
  bottom: -9%;
  right: -2px;
}

@media (min-width: 40rem) {
  .skills:after {
    bottom: 12%;
  }
}

@media (min-width: 55rem) {
  .skills:after {
    bottom: -18%;
  }
}

@media (min-width: 85rem) {
  .skills:after {
    content: url("3dRing.35c3a2b9.svg");
    bottom: 33%;
    left: 64%;
  }
}

.skills .tech-skills-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 48px;
  display: flex;
}

@media (min-width: 40rem) {
  .skills .tech-skills-container {
    flex-flow: wrap;
    align-items: start;
  }
}

.skills .soft-skills-container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 48px;
  display: flex;
}

@media (min-width: 40rem) {
  .skills .soft-skills-container {
    flex-flow: wrap;
    align-items: start;
  }
}

.skills .skills-title {
  color: var(--c-gray-light);
  border-bottom: 1px solid var(--c-gray-light);
  display: inline-block;
}

.skills .tech-skill-item {
  margin-bottom: 24px;
}

@media (min-width: 40rem) {
  .skills .tech-skill-item {
    width: 50%;
  }
}

@media (min-width: 55rem) {
  .skills .tech-skill-item {
    width: 30%;
  }
}

.skills .soft-skill-item {
  margin-bottom: 24px;
}

@media (min-width: 40rem) {
  .skills .soft-skill-item {
    width: 100%;
  }
}

@media (min-width: 55rem) {
  .skills .soft-skill-item {
    width: 50%;
  }
}

.projects {
  margin-bottom: 139px;
  padding: 0 24px;
}

.projects .projects-header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
  display: flex;
}

.projects .projects-container {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

@media (min-width: 40rem) {
  .projects .projects-container {
    flex-flow: wrap;
    justify-content: space-between;
    gap: 24px;
    display: flex;
  }
}

.projects .project-clump {
  width: 100%;
  margin-bottom: 69px;
  position: relative;
}

@media (min-width: 40rem) {
  .projects .project-clump {
    width: 47%;
  }
}

.projects .img-div {
  aspect-ratio: 4 / 3;
  max-width: 540px;
  z-index: -1;
  position: absolute;
}

.projects .project-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.projects .project-hover-area {
  aspect-ratio: 4 / 3;
  max-width: 541px;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-bottom: 20px;
  transition: all .2s;
  display: flex;
}

.projects .project-hover-area:hover {
  opacity: 1;
  background-color: #000c;
}

.projects .tech-container {
  flex-wrap: wrap;
  column-gap: 8px;
  display: flex;
}

@media (min-width: 40rem) {
  .projects .tech-container {
    column-gap: 24px;
  }
}

.projects .tech-item {
  text-transform: uppercase;
  margin-top: 7px;
}

.contact {
  background-color: var(--c-gray);
  padding: 84px 0 92px;
}

.contact .contact-text {
  text-align: center;
}

@media (min-width: 55rem) {
  .contact .contact-text {
    text-align: left;
  }
}

.contact .contact-p {
  max-width: 445px;
  margin-top: 36px;
}

.contact .contact-form {
  width: 100%;
  max-width: 445px;
  flex-direction: column;
  align-items: end;
  display: flex;
}

.contact .contact-container {
  max-width: 1110px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  position: relative;
}

@media (min-width: 55rem) {
  .contact .contact-container {
    flex-direction: row;
    align-items: start;
    gap: 24px;
  }
}

@media (min-width: 85rem) {
  .contact .contact-container {
    flex-direction: row;
    align-items: start;
    gap: 0;
  }
}

.contact .contact-container:before {
  content: url("3dRing.35c3a2b9.svg");
  position: absolute;
  bottom: 2px;
  left: -400px;
}

@media (min-width: 40rem) {
  .contact .contact-container:before {
    bottom: -78px;
    left: -300px;
  }
}

@media (min-width: 55rem) {
  .contact .contact-container:before {
    top: 255px;
    left: -257px;
  }
}

.footer {
  background-color: var(--c-gray);
  padding-bottom: 92px;
}

.footer .footer-container {
  border-top: 1px solid var(--c-white);
  max-width: 1120px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
  padding: 47px 20px 0;
  display: flex;
}

@media (min-width: 40rem) {
  .footer .footer-container {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }
}

.footer .footer-links {
  width: 192px;
  justify-content: space-between;
  display: flex;
}

.footer .footer-link {
  height: 24px;
  width: 24px;
  transition: transform .1s;
}

.footer .footer-link:hover {
  filter: brightness(0) saturate() invert() sepia(26%) saturate(4586%) hue-rotate(70deg) brightness(94%) contrast(89%);
  transform: scale(1.1);
}

/*# sourceMappingURL=index.7beb4e5a.css.map */
